import './Footer.css';
import { Link } from "react-router-dom";


function Footer() {
  return (
    <div className='footer'>


     <div className='footer-items'>
      Oliys 2022. All rights reserved.
     </div>


    </div>
  )
}



export default Footer;
